//MUI
import { Box, Typography } from '@mui/material'

const Heading = ({ title }) => {
  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="subtitle1" component="h1" mb={2}>
        OpenSTS
      </Typography>
      <Typography variant="h4" component="div" mb={4}>
        {title}
      </Typography>
    </Box>
  )
}

export default Heading
