//Components
import { generatePublishmentColumns } from './columns'

//UI
import DataTable from 'components/UI/DataTable'

const Publishments = ({ data }) => {
  return (
    <>
      <DataTable
        columns={generatePublishmentColumns()}
        rows={data}
        rowCount={data.length}
        paginationMode={'client'}
        disableColumnFilter={true}
      />
    </>
  )
}

export default Publishments
