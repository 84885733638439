import React from 'react'
import { Outlet } from 'react-router-dom'
import RequireGuest from 'layouts/RequireGuest'

const AuthLayout = () => {
  return (
    <RequireGuest>
      <Outlet />
    </RequireGuest>
  )
}

export default AuthLayout
