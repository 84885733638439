import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
// import { useNavigate } from 'react-router-dom'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getServices } from '../api/services'

//MUI
import { Paper, Button, Alert, Stack } from '@mui/material'

//Components
import { generateServicesColumns } from '../components/List/columns'
import ServiceCreationDrawer from '../components/Create/Drawer'
import ResetSecretModal from '../components/List/ResetSecretModal'
import DeleteServiceModal from '../components/List/DeleteServiceModal'
import EditServiceDrawer from '../components/Edit/Drawer'

//UI
import Title from 'components/UI/Title'
import DataTable from 'components/UI/DataTable'

const ServicesListPage = () => {
  const { setSnackbar } = useApp()
  // const navigate = useNavigate()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [secret, setSecret] = useState(null)
  const [deletingService, setDeletingService] = useState(null)
  const [resettingService, setResettingService] = useState(null)
  const [editingService, setEditingService] = useState(null)
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getServices({
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data,
          total: result?.total,
          page: result?.current_page,
        }))
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [pageState.page, pageState.pageSize])

  useEffect(() => {
    if (!secret) return

    navigator.clipboard.writeText(secret).then(
      function () {
        setSnackbar({
          children: 'Secret key copied to clipboard!',
          severity: 'success',
        })
      },
      function (err) {
        setSnackbar({
          children: 'Secret key copied to clipboard!',
          severity: 'error',
        })
      }
    )
  }, [secret, setSnackbar])

  const openDeleteModal = (service) => {
    setDeletingService(service)
  }

  const openResetModal = (service) => {
    setResettingService(service)
  }

  const openEditDrawer = (service) => {
    setEditingService(service)
  }

  const handleCreatedService = (response) => {
    setPageState((old) => ({
      ...old,
      data: [response?.service, ...pageState.data],
    }))

    setIsDrawerOpen(false)
    setSecret(response?.secret)
  }

  const handleResettedService = (response) => {
    setResettingService(null)
    setSecret(response?.secret)
  }

  const handleEditedService = (response) => {
    setPageState((old) => ({
      ...old,
      data: old.data.map((service) =>
        service.id === response.service.id ? response.service : service
      ),
    }))

    setEditingService(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleDeletedService = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.filter((service) => service.id !== deletingService?.id),
    }))

    setDeletingService(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  // const handleRowClick = (params) => {
  //   navigate(`/panel/services/${params.id}`)
  // }

  return (
    <>
      <Helmet title="Services" />

      <Stack spacing={2} direction="column">
        {secret && (
          <Alert variant="filled" severity="success" sx={{ my: 1 }} onClose={() => setSecret(null)}>
            This is the secret key of your service: {secret}
          </Alert>
        )}

        <Paper sx={{ p: 2 }}>
          <Title label="Services">
            <Button variant="contained" onClick={() => setIsDrawerOpen(true)}>
              New service
            </Button>
          </Title>

          <DataTable
            IsReadOnly
            loading={pageState.isLoading}
            columns={generateServicesColumns(openResetModal, openDeleteModal, openEditDrawer)}
            rows={pageState.data}
            rowCount={pageState.total}
            onPaginationModelChange={(model) => {
              setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
            }}
            disableColumnFilter={true}
            // onRowClick={handleRowClick} //TODO enable when publishements are available
          />
        </Paper>

        <ServiceCreationDrawer
          isOpen={isDrawerOpen}
          handleClose={() => setIsDrawerOpen(false)}
          callback={handleCreatedService}
        />

        <ResetSecretModal
          isOpen={!!resettingService}
          handleClose={() => setResettingService(null)}
          service={resettingService}
          callback={handleResettedService}
        />

        <EditServiceDrawer
          isOpen={!!editingService}
          handleClose={() => setEditingService(null)}
          service={editingService}
          callback={handleEditedService}
        />

        <DeleteServiceModal
          isOpen={!!deletingService}
          handleClose={() => setDeletingService(null)}
          service={deletingService}
          callback={handleDeletedService}
        />
      </Stack>
    </>
  )
}

export default ServicesListPage
