import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Api
import { getDevice } from '../api/devices'

//MUI
import { Paper, Stack, Button, Tabs, Tab } from '@mui/material'

//Components
import LinkedApplications from '../components/View/LinkedApplications/list'
import OpenVPNProfiles from '../components/View/OpenVPNProfiles/list'
import LinkDevicesModal from '../components/List/LinkDevicesModal'

//UI
import Title from 'components/UI/Title'

const tabs = [
  {
    key: 'linked-applications',
    label: 'Linked applications',
  },
  {
    key: 'openvpn-profiles',
    label: 'OpenVPN profiles',
  },
]

const DevicesViewPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { setIsBackdropOpen, setSnackbar } = useApp()

  const [device, setDevice] = useState(null)
  const [activeTab, setActiveTab] = useState(0)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [drawerMode, setDrawerMode] = useState('create')
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  useEffect(() => {
    const fetchDevice = async () => {
      try {
        setIsBackdropOpen(true)

        const result = await getDevice(id)

        setDevice(result?.data)
      } catch (e) {
        console.log(e)
      } finally {
        setIsBackdropOpen(false)
      }
    }

    fetchDevice()
  }, [id, setIsBackdropOpen, pageState])

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false)
  }

  const handleLinkedDevices = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((device) => {
        if (device?.applications_count) {
          device.applications_count += 1
        } else {
          device.applications_count = 0
        }

        return device
      }),
    }))

    handleDrawerClose()
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <Helmet title={'Devices - ' + device?.name} />

      <Paper sx={{ p: 2 }}>
        <Title label={device?.name ?? device?.mac_address}>
          <Stack direction={'row'} spacing={2}>
            <Button onClick={() => navigate('/panel/devices')} variant="outlined">
              Back
            </Button>
            {activeTab === 0 && (
              <Button
                variant="contained"
                onClick={() => {
                  setDrawerMode('link')
                  setIsDrawerOpen(true)
                }}
              >
                Link application
              </Button>
            )}

            {activeTab === 1 && (
              <Button
                variant="contained"
                onClick={() => {
                  setDrawerMode('create')
                  setIsDrawerOpen(true)
                }}
              >
                New OpenVPN profile
              </Button>
            )}
          </Stack>
        </Title>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Device tabs"
          centered
          sx={{ mb: 1 }}
        >
          {tabs.map((tab, index) => (
            <Tab label={tab.label} id={tab.key} key={index} />
          ))}
        </Tabs>

        {activeTab === 0 && (
          <LinkedApplications
            device={device}
            isDrawerOpen={isDrawerOpen}
            drawerMode={drawerMode}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            handleDrawerMode={setIsDrawerOpen}
            pageState={pageState}
            setPageState={setPageState}
          />
        )}

        {activeTab === 1 && (
          <OpenVPNProfiles
            deviceId={id}
            isDrawerOpen={isDrawerOpen}
            drawerMode={drawerMode}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            handleDrawerMode={setDrawerMode}
            pageState={pageState}
            setPageState={setPageState}
          />
        )}

        <LinkDevicesModal
          isOpen={drawerMode === 'link' && isDrawerOpen}
          devices={[id]}
          handleClose={handleDrawerClose}
          callback={handleLinkedDevices}
        />
      </Paper>
    </>
  )
}

export default DevicesViewPage
