import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

//Context
import { useAuth } from 'contexts/AuthProvider'

//API
import { createService } from 'features/services/api/services'
import { getApplications } from '../../../applications/api/applications'

//MUI
import {
  Drawer,
  Box,
  Typography,
  Alert,
  TextField,
  Stack,
  Button,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const ServiceCreationDrawer = ({ isOpen, handleClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)
  const [applications, setApplications] = useState([])

  const { currentUser } = useAuth()
  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getApplications()

        setApplications(result?.data)
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('applications', JSON.stringify(data.applications))

      const result = await createService({
        organisation_id: currentUser?.organisations?.[0].id,
        ...data,
      })

      reset()
      callback(result)
    } catch (e) {
      if (e?.response?.status === 400) {
        if (errors['name']) {
          setError('name', { type: 'api', message: errors['name'] })
        } else {
          setGeneralError(true)
        }
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: 450, p: 4, height: '100%', overflow: 'auto' }}
        >
          <Stack direction={'column'} justifyContent="space-between" sx={{ height: '100%' }}>
            <div>
              <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                New service
              </Typography>

              {generalError && (
                <Alert
                  severity="error"
                  sx={{
                    my: 1,
                  }}
                >
                  Something went wrong. Please contact the administrator.
                </Alert>
              )}

              <TextField
                label="Name"
                type="text"
                margin="normal"
                fullWidth
                required
                error={!!errors?.name}
                helperText={errors?.name?.message}
                autoFocus
                {...register('name')}
              />

              <Typography variant="body2" sx={{ mt: 2 }}>
                Link service to one or multiple applications
              </Typography>

              <Controller
                name="applications"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="selectLabel">Applications</InputLabel>
                    <Select
                      labelId="selectLabel"
                      fullWidth
                      label="Applications"
                      multiple
                      {...field}
                    >
                      {applications.map((application) => (
                        <MenuItem key={application.id} value={application.id}>
                          {application.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                sx={{ my: 1 }}
                fullWidth
              >
                Create
              </LoadingButton>

              <Button variant="text" fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default ServiceCreationDrawer
