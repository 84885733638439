//MUI
import { Container, styled, Typography } from '@mui/material'

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
}))

const Title = ({ label, children }) => {
  return (
    <StyledContainer disableGutters>
      <Typography component="h2" variant="h6" color="inherit">
        {label}
      </Typography>

      {children}
    </StyledContainer>
  )
}

export default Title
