import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useNavigate } from 'react-router-dom'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getService } from 'features/services/api/services'

//MUI
import { Paper, Button } from '@mui/material'

//Components
import Publishments from 'features/services/components/Publishment'

//UI
import Title from 'components/UI/Title'

const ServicesViewPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { setIsBackdropOpen } = useApp()

  const [service, setService] = useState(null)

  useEffect(() => {
    const fetchService = async () => {
      try {
        setIsBackdropOpen(true)

        const result = await getService(id)

        setService(result)
      } catch (e) {
        if (e?.response?.status === 404) {
          navigate('/panel/services')
        } else {
          console.log(e)
        }
      } finally {
        setIsBackdropOpen(false)
      }
    }

    fetchService()
  }, [id, navigate, setIsBackdropOpen])

  return (
    <>
      <Helmet title={'Services - ' + service?.name} />

      <Paper sx={{ p: 2 }}>
        <Title label={service?.name + ' - Publishments'}>
          <Button onClick={() => navigate('/panel/services')} variant="outlined">
            Back
          </Button>
        </Title>

        <Publishments data={service?.publishments ?? []} />
      </Paper>
    </>
  )
}

export default ServicesViewPage
