import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'

//Context
import { useAuth } from 'contexts/AuthProvider'

//Api
import { registerUser } from '../api/auth'

//MUI
import { Box, TextField, FormControlLabel, Checkbox, Typography, Alert } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//Components
import Heading from '../components/Heading'

const Register = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const { setToken } = useAuth()

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const result = await registerUser(data)

      setToken(result.token)

      navigate('/panel', { replace: true })
    } catch (e) {
      if (e?.response?.status === 400) {
        let errors = e.response.data.errors

        let errorFields = [
          'name',
          'email',
          'password',
          'password_confirmation',
          'organisation_name',
        ]

        errorFields.forEach((key) => {
          if (errors[key]) {
            setError(key, { type: 'api', message: errors[key] })
          }
        })
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Helmet title="Register" />

      <div className="mx-auto max-w-md py-36">
        <Heading title="Register" />

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ px: 2 }} lg={{ px: 0 }}>
          {generalError && (
            <Alert
              severity="error"
              sx={{
                mb: 2,
              }}
            >
              Something went wrong. Please contact the administrator.
            </Alert>
          )}
          
          <TextField
            label="Full name"
            type="text"
            margin="normal"
            fullWidth
            required
            autoFocus
            error={!!errors?.name}
            helperText={errors?.name?.message}
            {...register('name')}
          />

          <TextField
            label="Email"
            type="email"
            margin="normal"
            fullWidth
            required
            error={!!errors?.email}
            helperText={errors?.email?.message}
            {...register('email')}
          />

          <TextField
            label="Organisation name"
            type="text"
            margin="normal"
            fullWidth
            required
            error={!!errors?.organisation_name}
            helperText={errors?.organisation_name?.message}
            {...register('organisation_name')}
          />

          <TextField
            label="Password"
            type="password"
            margin="normal"
            fullWidth
            required
            error={!!errors?.password}
            helperText={
              <ul>
                {errors?.password?.message.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            }
            {...register('password')}
          />

          <TextField
            label="Repeat password"
            type="password"
            margin="normal"
            fullWidth
            required
            error={!!errors?.password_confirmation}
            helperText={errors?.password_confirmation?.message}
            {...register('password_confirmation')}
          />

          <FormControlLabel
            control={<Checkbox value="1" color="primary" {...register('terms')} required />}
            label="I accept the terms and conditions."
          />

          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            sx={{ mt: 3, mb: 2 }}
            fullWidth
          >
            Sign up
          </LoadingButton>

          <Link to="/login">
            <Typography variant="body2" component="span">
              {'Already have an account? Sign in.'}
            </Typography>
          </Link>
        </Box>
      </div>
    </>
  )
}

export default Register
