import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

//Context
import { useAuth } from 'contexts/AuthProvider'

//API
import { createApplication } from '../../../api/applications'
import { getDevices } from '../../../../devices/api/devices'

//MUI
import {
  Drawer,
  Box,
  Typography,
  Alert,
  TextField,
  Stack,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//Components
import { MuiFileInput } from 'mui-file-input'

const ApplicationCreationDrawer = ({ isOpen, handleClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)
  const [devices, setDevices] = useState([])

  const { currentUser } = useAuth()
  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      revision: {
        version_number: null,
        file: null,
      },
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getDevices()

        setDevices(result?.data)
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      let newData = { ...data }

      const formData = new FormData()

      if (data.revision?.file && data.revision?.version_number) {
        formData.append('revision[version_number]', newData.revision.version_number)
        if (data.revision?.file) {
          formData.append('revision[file]', newData.revision.file, newData.revision.file?.name)
        }
      }

      if (data.devices && data.devices.length > 0) {
        formData.append('devices', JSON.stringify(newData.devices))
      } else {
        delete newData.devices
      }

      const result = await createApplication({
        organisation_id: currentUser?.organisations?.[0].id,
        ...newData,
      })

      reset()
      callback(result)
    } catch (e) {
      if (e?.response?.status === 400) {
        if (errors['name']) {
          setError('name', { type: 'api', message: errors['name'] })
        } else {
          setGeneralError(true)
        }
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: 450, p: 4, height: '100%', overflow: 'auto' }}
        >
          <Stack direction={'column'} justifyContent="space-between" sx={{ height: '100%' }}>
            <div>
              <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                New application
              </Typography>

              {generalError && (
                <Alert
                  severity="error"
                  sx={{
                    my: 1,
                  }}
                >
                  Something went wrong. Please contact the administrator.
                </Alert>
              )}

              <TextField
                label="Name"
                type="text"
                margin="normal"
                fullWidth
                required
                error={!!errors?.name}
                helperText={errors?.name?.message}
                autoFocus
                {...register('name')}
              />

              <Typography variant="body2" sx={{ mt: 2 }}>
                Upload a first revision (optional)
              </Typography>

              <TextField
                label="Version number"
                type="text"
                margin="normal"
                fullWidth
                error={!!errors?.version_number}
                helperText={errors?.version_number?.message}
                {...register('revision.version_number')}
              />

              <Controller
                name="revision.file"
                control={control}
                required
                render={({ field, fieldState }) => (
                  <MuiFileInput
                    {...field}
                    label="File"
                    margin="normal"
                    placeholder="Upload a zip"
                    inputProps={{ accept: '.zip' }}
                    helperText={fieldState.invalid ? 'Please select a file.' : ''}
                    error={fieldState.invalid}
                  />
                )}
              />

              <Typography variant="body2" sx={{ mt: 2 }}>
                Link app to one or multiple devices (optional)
              </Typography>

              <Controller
                name="devices"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="selectLabel">Devices</InputLabel>
                    <Select labelId="selectLabel" fullWidth label="Devices" multiple {...field}>
                      {devices.map((device) => (
                        <MenuItem key={device.id} value={device.id}>
                          {device.name === null ? device.mac_address : device.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>

            <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                sx={{ my: 1 }}
                fullWidth
              >
                Create
              </LoadingButton>

              <Button variant="text" fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default ApplicationCreationDrawer
