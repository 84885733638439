import { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

//Services
import axios from 'services/Request'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)

  let authToken = localStorage.getItem('auth_token')

  const setToken = (token) => {
    localStorage.setItem('auth_token', JSON.stringify(token))
    authToken = token
  }

  const logout = useCallback(async () => {
    try {
      setIsLoggingOut(true)

      const result = await axios.get('/api/logout')

      if (result) {
        localStorage.removeItem('auth_token')
        setIsLoggingOut(false)
        navigate('/login', { replace: true })
      }
    } catch (error) {
      console.log(error)
    }
  }, [navigate])

  const getAccountInfo = useCallback(async () => {
    try {
      setIsLoadingUser(true)
      const result = await axios.get('/api/user')
      setCurrentUser(result)
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('auth_token')
        setIsLoggingOut(false)
        navigate('/login', { replace: true })
      }
    } finally {
      setIsLoadingUser(false)
    }
  }, [navigate])

  const isAuthenticated = useMemo(() => {
    return !!authToken
  }, [authToken])

  useEffect(() => {
    async function checkAuth() {
      if (isAuthenticated) {
        if (currentUser) return
        await getAccountInfo()
      }
    }

    checkAuth()
  }, [currentUser, isAuthenticated, getAccountInfo])

  const values = {
    isLoadingUser,
    setIsLoggingOut,
    isLoggingOut,
    isAuthenticated,
    setToken,
    logout,
    getAccountInfo,
    currentUser,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export default AuthContext
