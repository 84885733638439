import { createContext, useContext, useMemo, useState } from 'react'

//Contexts
import { useAuth } from './AuthProvider'

//MUI
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const AppContext = createContext()

export function useApp() {
  return useContext(AppContext)
}

export const AppProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState(null)
  const { isAuthenticated, isLoadingUser } = useAuth()
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)

  const drawerWidth = 240

  const isLoading = useMemo(() => {
    return isAuthenticated && isLoadingUser
  }, [isAuthenticated, isLoadingUser])

  const values = {
    isDrawerOpen,
    setIsDrawerOpen,
    drawerWidth,
    snackbar,
    setSnackbar,
    setIsBackdropOpen,
  }

  return (
    <AppContext.Provider value={values}>
      {children}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || isBackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AppContext.Provider>
  )
}
