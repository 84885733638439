import axios, { axiosFileClient } from 'services/Request'

export function getApplicationManagers(params) {
  return axios.get('/api/application_managers', { params })
}

export function createApplicationManager(data) {
  return axiosFileClient.post(`/api/application_managers`, data)
}

export function editApplicationManager(appManagerId, data) {
  return axiosFileClient.post(`/api/application_managers/${appManagerId}?_method=put`, data)
}

export function associateDevices(appManagerId, data) {
  return axios.post(`/api/application_managers/${appManagerId}/associate`, data)
}
