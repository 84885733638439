import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

//API
import {
  getApplicationManagers,
  associateDevices,
} from 'features/application-managers/api/application-managers'

//MUI
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//UI
import Modal from 'components/UI/Modal'

const LinkAppManagerModal = ({ isOpen, devices, handleClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(false)
  const [appManagers, setAppManagers] = useState([])

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const result = await associateDevices(data?.application_manager_id, {
        devices: devices,
      })

      reset()
      callback(result)
    } catch (e) {
      setGeneralError(true)
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchAppManagers = async () => {
      try {
        const result = await getApplicationManagers({
          pagination: 0,
        })

        setAppManagers(result)
      } catch (e) {
        console.log(e)
      }
    }

    fetchAppManagers()
  }, [])

  return (
    <>
      <Modal
        title="Link to app manager"
        width={500}
        isOpen={isOpen}
        handleClose={() => {
          setGeneralError(false)
          handleClose()
        }}
      >
        {generalError && (
          <Alert
            severity="error"
            sx={{
              my: 1,
            }}
          >
            Something went wrong. Please contact the administrator.
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {appManagers.length ? (
            <>
              <FormControl
                fullWidth
                margin="normal"
                required
                error={!!errors?.application_manager_id}
              >
                <InputLabel id="application-manager-select-helper-label">App manager</InputLabel>
                <Controller
                  name="application_manager_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      labelId="application-manager-select-helper-label"
                      id="application-manager-select"
                      label="App manager"
                      defaultValue={''}
                      value={value ?? ''}
                      onChange={onChange}
                    >
                      {appManagers.map((appManager, index) => (
                        <MenuItem value={appManager?.id} key={index}>
                          {appManager?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors?.application_manager_id?.message}</FormHelperText>
              </FormControl>

              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                sx={{ my: 1 }}
                fullWidth
              >
                Link
              </LoadingButton>
            </>
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Box>
      </Modal>
    </>
  )
}

export default LinkAppManagerModal
