import axios from 'services/Request'

export function getServices(params) {
  return axios.get('/api/services', { params })
}

export function getService(serviceId) {
  return axios.get(`/api/services/${serviceId}`)
}

export function createService(data) {
  return axios.post('/api/services/', data)
}

export function editService(serviceId, data) {
  return axios.put(`/api/services/${serviceId}`, data)
}

export function deleteService(serviceId) {
  return axios.delete(`/api/services/${serviceId}`)
}

export function associateApplications(serviceId, data) {
  return axios.post(`/api/services/${serviceId}/associate`, data)
}

export function resetSecret(serviceId) {
  return axios.get(`/api/services/${serviceId}/reset`)
}
