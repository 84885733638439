//MUI
import { Chip, Stack, IconButton, Tooltip } from '@mui/material'

//Icons
import LinkOffIcon from '@mui/icons-material/LinkOff'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export const generateApplicationColumns = ({ handleUnlink }) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: true,
    },
    {
      field: 'is_installed',
      renderHeader: (params) => (
        <strong>
          {'Installed '}
          <Tooltip title="Your applications will automatically install to linked devices. This column shows the installation status">
            <HelpOutlineIcon fontSize="small" color="disabled" />
          </Tooltip>
        </strong>
      ),
      sortable: false,
      flex: true,
      renderCell: (params) => {
        if (params.row?.is_installed) {
          return <Chip label="Installed" color="success" />
        } else {
          return <Chip label="Not installed" />
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <Stack direction={'row'} spacing={1}>
              <IconButton color="primary" onClick={() => handleUnlink(params.row)}>
                <LinkOffIcon fontSize="small" />
              </IconButton>
            </Stack>
          </>
        )
      },
    },
  ]
}
