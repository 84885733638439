import { useState } from 'react'
import { useForm } from 'react-hook-form'

//Context
import { useAuth } from 'contexts/AuthProvider'

//API
import { claimDevice } from '../../api/devices'

//MUI
import { Box, TextField, Alert, Drawer, Typography, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const ClaimDeviceDrawer = ({ isOpen, handleClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)
  const { currentUser } = useAuth()
  const defaultFields = [{ id: 1 }, { id: 2 }]
  const [fields, setFields] = useState(defaultFields)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  const addExtraDevice = () => {
    setFields([...fields, { id: fields.length + 1 }])
  }

  const resetFields = () => {
    setFields(defaultFields)
  }

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const formData = new FormData()
      data.mac_addresses.forEach((mac_address, index) => {
        formData.append(`mac_addresses[${index}]`, mac_address)
      })

      data.mac_addresses = data.mac_addresses.filter((mac_address) => mac_address.trim() !== '')

      const result = await claimDevice({
        organisation_id: currentUser?.organisations?.[0].id,
        ...data,
      })

      reset()
      callback(result)
    } catch (e) {
      if (e?.response?.status === 400) {
        let errors = e.response.data.errors

        if (errors['mac_address']) {
          setError('mac_address', { type: 'api', message: errors['mac_address'] })
        } else {
          setGeneralError(true)
        }
      } else if (e?.response?.status === 404 || e?.response?.status === 409) {
        setError('mac_address', { type: 'api', message: e?.response.data.message })
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
      resetFields()
    }
  }

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: 450, p: 4, height: '100%', overflow: 'auto' }}
      >
        <Stack direction={'column'} justifyContent="space-between" sx={{ height: '100%' }}>
          <div>
            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
              Add a device
            </Typography>

            {generalError && (
              <Alert
                severity="error"
                sx={{
                  my: 1,
                }}
              >
                Something went wrong. Please contact the administrator.
              </Alert>
            )}

            {fields.map((field, index) => (
              <TextField
                key={field.id}
                label={index === 0 ? 'MAC address' : 'Add another device'}
                type="text"
                margin="normal"
                fullWidth
                required={index === 0}
                error={!!errors?.[`mac_addresses[${index}]`]}
                helperText={errors?.[`mac_addresses[${index}]`]?.message}
                autoFocus={index === 0}
                onClick={index === 0 ? null : addExtraDevice}
                {...register(`mac_addresses[${index}]`)}
              />
            ))}
          </div>

          <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              sx={{ my: 1 }}
              fullWidth
            >
              Add
            </LoadingButton>
            <Button variant="text" fullWidth onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default ClaimDeviceDrawer
