import axios from 'services/Request'

export function getDevices(params) {
  return axios.get('/api/devices', { params })
}

export function getDevice(deviceId) {
  return axios.get(`/api/devices/${deviceId}`);
}

export function claimDevice(data) {
  return axios.post('/api/devices/claim', data)
}

export function editDevice(deviceId, data) {
  return axios.put(`/api/devices/${deviceId}`, data)
}

export function deleteDevice(deviceId) {
  return axios.delete(`/api/devices/${deviceId}`)
}

export function linkDevices(data) {
  return axios.post('/api/devices/link', data)
}
