import { saveAs } from 'file-saver'

//MUI
import { IconButton, Tooltip } from '@mui/material'

//Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'

const handleDownload = async (revision) => {
  saveAs(revision.download_link, revision.version_numer + '.zip')
}

export const generateRevisionsColumns = ({ openDeleteModal, handleEdit }) => {
  return [
    {
      field: 'version_number',
      headerName: 'Version',
      sortable: false,
      flex: true,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 150,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Download revision">
              <IconButton color="primary" onClick={() => handleDownload(params.row)}>
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit revision">
              <IconButton onClick={() => handleEdit(params.row)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete revision">
              <IconButton color="error" onClick={() => openDeleteModal(params.row)}>
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]
}
