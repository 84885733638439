import { useState, useEffect } from 'react'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getRevisions } from '../../../../../api/revisions'

//Components
import { generateRevisionsColumns } from './columns'
import RevisionCreationDrawer from '../Create/Drawer'
import RevisionEditDrawer from '../Edit/Drawer'
import DeleteRevisionModal from './DeleteRevisionModal'

//UI
import DataTable from 'components/UI/DataTable'

const ApplicationRevisions = ({ applicationId, isDrawerOpen, handleDrawerClose }) => {
  const { setSnackbar } = useApp()
  const [editingRevision, setEditingRevision] = useState(null)
  const [deletingRevision, setDeletingRevision] = useState(null)
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    const fetchRevisions = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getRevisions(applicationId, {
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data,
          total: result?.total,
          page: result?.current_page,
        }))
      } catch (e) {
        console.log(e)
      }
    }

    fetchRevisions()
  }, [pageState.page, pageState.pageSize, applicationId])

  const openDeleteModal = (revision) => {
    setDeletingRevision(revision)
  }

  const handleDeletedRevision = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.filter((revision) => revision.id !== deletingRevision?.id),
    }))

    setDeletingRevision(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleEdit = (revision) => {
    setEditingRevision(revision)
  }

  const handleCreatedRevision = (response) => {
    setPageState((old) => ({
      ...old,
      data: [response?.revision, ...pageState.data],
    }))

    handleDrawerClose()
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleEditedRevision = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((revision) => {
        if (revision?.id === response?.revision?.id) {
          return response?.revision
        }

        return revision
      }),
    }))

    setEditingRevision(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <DataTable
        loading={pageState.isLoading}
        columns={generateRevisionsColumns({ openDeleteModal, handleEdit })}
        rows={pageState.data}
        rowCount={pageState.total}
        onPaginationModelChange={(model) => {
          setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
        }}
        disableColumnFilter={true}
        disableSelectionOnClick={true}
      />

      <RevisionCreationDrawer
        applicationId={applicationId}
        isOpen={isDrawerOpen}
        handleClose={handleDrawerClose}
        callback={handleCreatedRevision}
      />

      <RevisionEditDrawer
        applicationId={applicationId}
        revision={editingRevision}
        isOpen={!!editingRevision}
        handleClose={() => setEditingRevision(null)}
        callback={handleEditedRevision}
      />

      <DeleteRevisionModal
        isOpen={!!deletingRevision}
        handleClose={() => setDeletingRevision(null)}
        revision={deletingRevision}
        callback={handleDeletedRevision}
      />
    </>
  )
}

export default ApplicationRevisions
