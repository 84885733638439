import { useState, useEffect } from 'react'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getProfiles } from '../../../api/openvpn-profiles'

//Components
import { generateVPNColumns } from './columns'
import OpenVPNProfileCreationDrawer from 'features/devices/components/View/OpenVPNProfiles/create'
import DeleteProfileModal from './DeleteProfileModal'

//UI
import DataTable from 'components/UI/DataTable'

const OpenVPNProfiles = ({
  deviceId,
  isDrawerOpen,
  drawerMode,
  handleDrawerOpen,
  handleDrawerClose,
  handleDrawerMode,
  pageState,
  setPageState,
}) => {
  const { setSnackbar } = useApp()

  const [deletingProfile, setDeletingProfile] = useState(null)
  const [editingProfile, setEditingProfile] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getProfiles(deviceId, {
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data,
          total: result?.total,
          page: result?.current_page,
        }))
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [deviceId, pageState.page, pageState.pageSize, setPageState])

  const openDeleteModal = (profile) => {
    setDeletingProfile(profile)
  }

  const handleEdit = (revision) => {
    handleDrawerMode('edit')
    setEditingProfile(revision)
    handleDrawerOpen()
  }

  const handleCreatedProfile = (response) => {
    setPageState((old) => ({
      ...old,
      data: [response?.openvpn_profile, ...pageState.data],
    }))

    handleDrawerClose()
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleEditedProfile = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((profile) => {
        if (profile?.id === response?.openvpn_profile?.id) {
          return response?.openvpn_profile
        }

        return profile
      }),
    }))

    handleDrawerClose()
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleDeletedProfile = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.filter((profile) => profile.id !== deletingProfile?.id),
    }))

    setDeletingProfile(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <DataTable
        loading={pageState.isLoading}
        columns={generateVPNColumns({ openDeleteModal, handleEdit })}
        rows={pageState.data}
        rowCount={pageState.total}
        onPaginationModelChange={(model) => {
          setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
        }}
        disableColumnFilter={true}
      />

      <OpenVPNProfileCreationDrawer
        mode={drawerMode}
        deviceId={deviceId}
        profile={editingProfile}
        isOpen={isDrawerOpen}
        handleClose={handleDrawerClose}
        callback={drawerMode === 'create' ? handleCreatedProfile : handleEditedProfile}
      />

      <DeleteProfileModal
        isOpen={!!deletingProfile}
        handleClose={() => setDeletingProfile(null)}
        profile={deletingProfile}
        callback={handleDeletedProfile}
      />
    </>
  )
}

export default OpenVPNProfiles
