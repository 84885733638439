import { useState } from 'react'

//API
import { resetSecret } from 'features/services/api/services'

//MUI
import { Box, Typography, Alert, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//UI
import Modal from 'components/UI/Modal'

const ResetSecretModal = ({ isOpen, handleClose, service, callback }) => {
  const [isResetting, setIsResetting] = useState(false)
  const [generalError, setGeneralError] = useState(false)

  const handleReset = async () => {
    try {
      setIsResetting(true)

      const result = await resetSecret(service?.id)

      callback(result)
    } catch (e) {
      setGeneralError(true)
    } finally {
      setIsResetting(false)
    }
  }

  return (
    <>
      <Modal title="Reset the secret" width={400} isOpen={isOpen} handleClose={handleClose}>
        {generalError && (
          <Alert
            severity="error"
            sx={{
              my: 1,
            }}
          >
            Something went wrong. Please contact the administrator.
          </Alert>
        )}

        <Box component="div">
          <Typography variant="body1" component="p" sx={{ my: 2 }}>
            Are you sure that you want to reset the secret for service {service?.name}?
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={handleClose} fullWidth>
              No, cancel it
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isResetting}
              onClick={handleReset}
              fullWidth
            >
              Yes, reset
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

export default ResetSecretModal
