export const generateApplicationManagersColumns = () => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      sortable: false,
      flex: true,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      renderCell: (params) => {
        return <></>
      },
    },
  ]
}
