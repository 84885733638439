//Context
import { useNavigate } from 'react-router-dom'

//MUI
import { Button, Paper, Stack, Typography } from '@mui/material'

//Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import InstallMobileIcon from '@mui/icons-material/InstallMobile'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'

const Dashboard = () => {
  const navigate = useNavigate()

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack spacing={10} direction="column" p={4}>
        <Typography variant="h3" align="center">
          Get started with OpenSTS!
        </Typography>
        <Stack direction="row">
          <Stack spacing={4} direction="column" alignItems="center" width="33%" mx={4}>
            <AddCircleOutlineIcon color="primary" style={{ fontSize: '7.5em' }} />
            <Typography variant="subtitle1" align="center" fontStyle="regular">
              1. Register your devices using their MAC addresses
            </Typography>
          </Stack>
          <Stack spacing={4} direction="column" alignItems="center" width="33%" mx={4}>
            <InstallMobileIcon color="primary" style={{ fontSize: '7.5em' }} />
            <Typography variant="subtitle1" align="center">
              2. Install software by linking your devices to an application
            </Typography>
          </Stack>
          <Stack spacing={4} direction="column" alignItems="center" width="33%" mx={4}>
            <ScatterPlotIcon color="primary" style={{ fontSize: '7.5em' }} />
            <Typography variant="subtitle1" align="center">
              3. Start collecting data by connecting your application to a service
            </Typography>
          </Stack>
        </Stack>
        <Button
          variant="contained"
          color="primary"
          size="large"
          mx={20}
          onClick={() => navigate('/panel/devices')}
        >
          Get started
        </Button>
      </Stack>
    </Paper>
  )
}

export default Dashboard
