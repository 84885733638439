import { Outlet } from 'react-router-dom'
import RequireAuth from 'layouts/RequireAuth'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Components
import Navbar from './Navbar'
import Sidebar from './Sidebar'

//MUI
import { Box, Toolbar, Container, Grid, Snackbar, Alert } from '@mui/material'

const PanelLayout = () => {
  const { snackbar, setSnackbar } = useApp()

  return (
    <RequireAuth>
      <Box sx={{ display: 'flex' }}>
        <Navbar />
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Outlet />
              </Grid>
            </Grid>
          </Container>
        </Box>

        {!!snackbar && (
          <Snackbar open autoHideDuration={6000} onClose={() => setSnackbar(null)}>
            <Alert onClose={() => setSnackbar(null)} {...snackbar} />
          </Snackbar>
        )}
      </Box>
    </RequireAuth>
  )
}

export default PanelLayout
