import { useMemo, useState, Fragment } from 'react'

//Contexts
import { useAuth } from 'contexts/AuthProvider'

//MUI
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material'

//Icons
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'

export default function AccountMenu() {
  const { logout, isLoggingOut, currentUser } = useAuth()
  const [open, setOpen] = useState(false)

  const isOpen = useMemo(() => {
    return isLoggingOut ? true : open
  }, [isLoggingOut, open])

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            size="small"
            sx={{ ml: 2 }}
            id="anchor"
            onClick={() => setOpen(!open)}
            aria-controls={isOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{ currentUser?.name?.charAt(0) }</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={() => {
          return document.getElementById('anchor')
        }}
        id="account-menu"
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            {isLoggingOut ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              <Logout fontSize="small" />
            )}
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
