import { useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'

//API
import { editApplication } from '../../../api/applications'

//MUI
import { Drawer, Box, Typography, Alert, TextField, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const ApplicationEditingDrawer = ({ isOpen, handleClose, callback, application }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const result = await editApplication(application?.id, {
        name: data.name,
      })

      reset()
      callback(result, application)
    } catch (e) {
      if (e?.response?.status === 400) {
        if (errors['name']) {
          setError('name', { type: 'api', message: errors['name'] })
        } else {
          setGeneralError(true)
        }
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  const defaultValue = useMemo(() => {
    reset()
    return application?.name ?? ''
  }, [application, reset])

  return (
    <>
      <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ width: 450, p: 4, height: '100%', overflow: 'auto' }}
        >
          <Stack direction={'column'} justifyContent="space-between" sx={{ height: '100%' }}>
            <div>
              <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                Edit application
              </Typography>

              {generalError && (
                <Alert
                  severity="error"
                  sx={{
                    my: 1,
                  }}
                >
                  Something went wrong. Please contact the administrator.
                </Alert>
              )}

              <TextField
                label="New name"
                type="text"
                margin="normal"
                fullWidth
                required
                error={!!errors?.name}
                helperText={errors?.name?.message}
                autoFocus
                defaultValue={defaultValue}
                {...register('name')}
              />
            </div>

            <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                sx={{ my: 1 }}
                fullWidth
              >
                Save
              </LoadingButton>

              <Button variant="text" fullWidth onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default ApplicationEditingDrawer
