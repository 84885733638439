import { useState } from 'react'

//API
import { deleteApplication } from '../../../api/applications'

//MUI
import { Box, Typography, Alert, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//UI
import Modal from 'components/UI/Modal'

const DeleteApplicationModal = ({ isOpen, handleClose, application, callback }) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [generalError, setGeneralError] = useState(false)

  const handleDelete = async () => {
    try {
      setIsDeleting(true)

      const result = await deleteApplication(application?.id)

      callback(result)
    } catch (e) {
      setGeneralError(true)
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Modal title="Delete an application" width={400} isOpen={isOpen} handleClose={handleClose}>
      {generalError && (
        <Alert
          severity="error"
          sx={{
            my: 1,
          }}
        >
          Something went wrong. Please contact the administrator.
        </Alert>
      )}

      <Box component="div">
        <Typography variant="body1" component="p" sx={{ my: 2 }}>
          Are you sure that you want to delete the application {application?.name}? All of the
          revisions of the application will be lost.
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={handleClose} fullWidth>
            No, cancel it
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isDeleting}
            onClick={handleDelete}
            fullWidth
          >
            Delete
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}

export default DeleteApplicationModal
