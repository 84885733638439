import { useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'

//API
import { editDevice } from '../../api/devices'

//MUI
import { Box, TextField, Alert, Drawer, Typography, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const EditDeviceDrawer = ({ isOpen, handleClose, callback, device }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const result = await editDevice(device?.id, {
        name: data.name,
      })

      reset()
      callback(result)
    } catch (e) {
      if (e?.response?.status === 400) {
        let errors = e.response.data.errors

        if (errors['name']) {
          setError('name', { type: 'api', message: errors['name'] })
        } else {
          setGeneralError(true)
        }
      } else if (e?.response?.status === 404 || e?.response?.status === 409) {
        setError('name', { type: 'api', message: e?.response.data.message })
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const defaultValue = useMemo(() => {
    reset()
    return device?.name || ''
  }, [device, reset])

  return (
    <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: 450, p: 4, height: '100%', overflow: 'auto' }}
      >
        <Stack direction={'column'} justifyContent="space-between" sx={{ height: '100%' }}>
          <div>
            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
              Edit device
            </Typography>

            {generalError && (
              <Alert
                severity="error"
                sx={{
                  my: 1,
                }}
              >
                Something went wrong. Please contact the administrator.
              </Alert>
            )}

            <TextField
              label="New name"
              type="text"
              margin="normal"
              fullWidth
              required
              error={!!errors?.name}
              helperText={errors?.name?.message}
              autoFocus
              defaultValue={defaultValue}
              {...register('name')}
            />
          </div>

          <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              sx={{ my: 1 }}
              fullWidth
            >
              Save
            </LoadingButton>
            <Button variant="text" fullWidth onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default EditDeviceDrawer
