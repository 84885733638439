import { useState, useEffect, useMemo } from 'react'

//Contexts
import { useApp } from 'contexts/AppProvider'

//UI
import DataTable from 'components/UI/DataTable'

//Components
import { generateApplicationColumns } from './columns'
import UnlinkApplicationModal from './UnlinkApplicationModal'

const LinkedApplications = ({ device }) => {
  const { setSnackbar } = useApp()

  const [applications, setApplications] = useState([])
  const [unlinkingApplication, setUnlinkingApplication] = useState(null)

  useEffect(() => {
    if (!device) return

    setApplications(device?.applications ?? [])
  }, [device])

  const handleUnlink = (application) => {
    setUnlinkingApplication(application)
  }

  const handleUnlinkedApplication = (response) => {
    setApplications((old) =>
      applications.filter((application) => application.id !== unlinkingApplication?.id)
    )

    setUnlinkingApplication(false)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <DataTable
        columns={generateApplicationColumns({ handleUnlink })}
        rows={applications}
        rowCount={applications.length}
        paginationMode={'client'}
        disableColumnFilter={true}
      />

      <UnlinkApplicationModal
        isOpen={!!unlinkingApplication}
        handleClose={() => setUnlinkingApplication(null)}
        deviceId={device?.id}
        application={unlinkingApplication}
        callback={handleUnlinkedApplication}
      />
    </>
  )
}

export default LinkedApplications
