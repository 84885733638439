import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

//Context
import { useAuth } from 'contexts/AuthProvider'
import { useApp } from 'contexts/AppProvider'

//MUI
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import {
  Toolbar,
  IconButton,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

//Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'
import DevicesIcon from '@mui/icons-material/Devices'
import AppsIcon from '@mui/icons-material/Apps'
import SettingsIcon from '@mui/icons-material/Settings'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'

const Sidebar = () => {
  const { currentUser } = useAuth()
  const { isDrawerOpen, setIsDrawerOpen, drawerWidth } = useApp()
  const location = useLocation()

  const listItems = useMemo(() => {
    let items = [
      {
        text: 'Dashboard',
        icon: <SpaceDashboardIcon />,
        link: '/panel',
      },
      {
        text: 'Devices',
        icon: <DevicesIcon />,
        link: '/panel/devices',
      },
      {
        text: 'Applications',
        icon: <AppsIcon />,
        link: '/panel/applications',
      },
      {
        text: 'Services',
        icon: <SystemUpdateAltIcon />,
        link: '/panel/services',
      },
    ]

    if (currentUser?.roles) {
      currentUser?.roles?.forEach((role, index) => {
        if (
          role?.permissions.find(
            (permission) =>
              permission?.resource === 'application_manager' && permission?.action === 'list'
          )
        ) {
          items.push({
            text: 'App managers',
            icon: <SettingsIcon />,
            link: '/panel/application-managers',
          })
        }
      })
    }

    return items
  }, [currentUser?.roles])

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  })

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  })

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  )

  return (
    <Drawer variant="permanent" open={isDrawerOpen}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {listItems.map((item, index) => (
          <ListItemButton
            component={Link}
            to={item.link}
            selected={item.link === location.pathname}
            key={index}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  )
}

export default Sidebar
