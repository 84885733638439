//MUI
import { IconButton, Tooltip, Chip } from '@mui/material'

//Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import LinkIcon from '@mui/icons-material/Link'
import EditIcon from '@mui/icons-material/Edit'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

export const generateApplicationsColumns = (openDeleteModal, openEditModal) => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.name}</>
      },
      flex: true,
    },
    {
      field: 'linked_services',
      renderHeader: (params) => (
        <strong>
          {'Linked services '}
          <Tooltip title="To collect data with your apps, they must be linked to a service">
            <HelpOutlineIcon fontSize="small" color="disabled" />
          </Tooltip>
        </strong>
      ),
      description: '',
      sortable: false,
      flex: true,
      renderCell: (params) => {
        if (params.row.is_linked) {
          return <Chip label={params.row.services.length} icon={<LinkIcon />} color="success" />
        }

        return <Chip label="Not linked" color="warning" />
      },
    },
    {
      field: 'revisions_count',
      renderHeader: (params) => (
        <strong>
          {'Revisions '}
          <Tooltip title="An application can be updated over time, resulting in different versions, called revisions">
            <HelpOutlineIcon fontSize="small" color="disabled" />
          </Tooltip>
        </strong>
      ),
      sortable: false,
      flex: true,
    },
    {
      field: 'latest_revision',
      headerName: 'Latest revision',
      sortable: false,
      flex: true,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit device">
              <IconButton
                color="white"
                onClick={(event) => {
                  event.stopPropagation()
                  openEditModal(params.row)
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete application">
              <IconButton
                color="error"
                onClick={(event) => {
                  event.stopPropagation()
                  openDeleteModal(params.row)
                }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]
}
