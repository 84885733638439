import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'

//Context
import { useAuth } from 'contexts/AuthProvider'

//Api
import { authenticate } from '../api/auth'

//MUI
import { Box, Typography, TextField, FormControlLabel, Checkbox, Alert } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//Components
import Heading from '../components/Heading'

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()
  const navigate = useNavigate()
  const { setToken } = useAuth()

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const result = await authenticate({
        email: data.email,
        password: data.password,
        remember_me: data.remember_me === false ? 0 : 1,
      })

      setToken(result.token)

      navigate('/panel', { replace: true })
    } catch (e) {
      if (e?.response?.status === 404) {
        setError('email', { type: 'api', message: e.response.data.message })
      } else if (e?.response?.status === 401) {
        setError('password', { type: 'api', message: e.response.data.message })
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Helmet title="Login" />

      <div className="mx-auto max-w-md py-36">
        <Heading title="Login" />

        {generalError && (
          <Alert
            severity="error"
            sx={{
              mb: 2,
            }}
          >
            Something went wrong. Please contact the administrator.
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ px: 2 }} lg={{ px: 0 }}>
          <TextField
            label="Email"
            type="email"
            margin="normal"
            fullWidth
            required
            autoFocus
            error={!!errors?.email}
            helperText={errors?.email?.message}
            {...register('email')}
          />
          <TextField
            label="Password"
            type="password"
            margin="normal"
            fullWidth
            required
            error={!!errors?.password}
            helperText={errors?.password?.message}
            {...register('password')}
          />
          <FormControlLabel
            control={<Checkbox value="1" color="primary" {...register('remember_me')} />}
            label="Remember me"
          />
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isLoading}
            sx={{ mt: 3, mb: 2 }}
            fullWidth
          >
            Sign in
          </LoadingButton>

          <Link to="/register">
            <Typography variant="body2" component="span">
              {"Don't have an account? Sign up."}
            </Typography>
          </Link>
        </Box>
      </div>
    </>
  )
}

export default Login
