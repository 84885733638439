import { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'

//Contexts
import { useApp } from 'contexts/AppProvider'

//Api
import { getApplicationManagers } from '../api/application-managers'

//MUI
import { Paper, Button } from '@mui/material'

//Components
import { generateApplicationManagersColumns } from '../components/List/columns'
import ApplicationManagerCreationDrawer from '../components/Create/Drawer'

//UI
import Title from 'components/UI/Title'
import DataTable from 'components/UI/DataTable'

const ApplicationManagersListPage = () => {
  const { setSnackbar } = useApp()

  const [drawerMode, setDrawerMode] = useState('create')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getApplicationManagers({
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data,
          total: result?.total,
          page: result?.current_page,
        }))
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [pageState.page, pageState.pageSize])

  const processRowUpdate = useCallback(async (newRow, oldRow) => {
    // TODO
  }, [])

  const handleCreatedAppManager = (response) => {
    setPageState((old) => ({
      ...old,
      data: [response?.application_manager, ...pageState.data]
    }))

    setIsDrawerOpen(false)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleEditedAppManager = (response) => {
    //TODO
  }

  return (
    <>
      <Helmet title="App managers" />

      <Paper sx={{ p: 2 }}>
        <Title label="App managers">
          <Button variant="contained" onClick={() => setIsDrawerOpen(true)}>
            New app manager
          </Button>
        </Title>

        <DataTable
          loading={pageState.isLoading}
          columns={generateApplicationManagersColumns()}
          rows={pageState.data}
          rowCount={pageState.total}
          onPaginationModelChange={(model) => {
            setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
          }}
          disableColumnFilter={true}
          processRowUpdate={processRowUpdate}
        />
      </Paper>

      <ApplicationManagerCreationDrawer
        mode={drawerMode}
        appManager={null}
        isOpen={isDrawerOpen}
        handleClose={() => setIsDrawerOpen(false)}
        callback={drawerMode === 'create' ? handleCreatedAppManager : handleEditedAppManager}
      />
    </>
  )
}

export default ApplicationManagersListPage
