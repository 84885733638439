//MUI
import { IconButton, Tooltip } from '@mui/material'

//Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import EditIcon from '@mui/icons-material/Edit'

export const generateServicesColumns = (openResetModal, openDeleteModal, openEditDrawer) => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      sortable: false,
      flex: true,
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 150,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Regenerate secret">
              <IconButton
                color="primary"
                onClick={(event) => {
                  event.stopPropagation()
                  openResetModal(params.row)
                }}
              >
                <RestartAltIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit service">
              <IconButton
                color="white"
                onClick={(event) => {
                  event.stopPropagation()
                  openEditDrawer(params.row)
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete service">
              <IconButton
                color="error"
                onClick={(event) => {
                  event.stopPropagation()
                  openDeleteModal(params.row)
                }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]
}
