import { Helmet } from 'react-helmet-async'

//Context
import { AuthProvider } from 'contexts/AuthProvider'
import { AppProvider } from 'contexts/AppProvider'

//Routes
import Routes from 'routes'

//MUI
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AuthProvider>
        <AppProvider>
          <Helmet titleTemplate="%s | OpenSTS" />
          <Routes />
        </AppProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
