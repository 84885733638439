import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

const getToken = () => {
  let token = localStorage.getItem('auth_token')
  if (!token) return

  try {
    token = JSON.parse(token)
  } catch (_) {
    console.error('error in parsing auth token.')
  }

  return token
}

const addAuthTokenInterceptor = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      config.headers['Authorization'] = `Bearer ${getToken()}`
      return config
    },
    (e) => {
      return Promise.reject(e)
    }
  )
}

const addResponseInterceptor = (instance) => {
  instance.interceptors.response.use(
    (res) => {
      if (res && res.data) return res.data
      return res
    },
    (e) => {
      return Promise.reject(e)
    }
  )
}

const createApiClient = (type) => {
  return axios.create({
    timeout: 60000,
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': type,
    },
  })
}

const addCSRFInterceptor = (instance) => {
  instance.interceptors.response.use(
    (res) => res,
    async (e) => {
      const config = e.config

      if (e?.response?.status !== 419 || config._retry) {
        return Promise.reject(e)
      }

      config._retry = true

      try {
        await csrf()
        return instance(config)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  )
}

const axiosClient = createApiClient('application/json')
addAuthTokenInterceptor(axiosClient)
addResponseInterceptor(axiosClient)
addCSRFInterceptor(axiosClient)

const axiosFileClient = createApiClient('multipart/form-data')
addAuthTokenInterceptor(axiosFileClient)
addResponseInterceptor(axiosFileClient)
addCSRFInterceptor(axiosFileClient)

const csrf = () => axiosClient.get('/sanctum/csrf-cookie')

export default axiosClient
export { csrf, axiosFileClient }
