import { useCallback } from 'react'

//Contexts
import { useApp } from 'contexts/AppProvider'

//MUI
import { DataGrid } from '@mui/x-data-grid'

const DataTable = ({ paginationMode, ...props }) => {
  const { setSnackbar } = useApp()

  const handleProcessRowUpdateError = useCallback(
    (error) => {
      console.log(error)
      setSnackbar({ children: error.message, severity: 'error' })
    },
    [setSnackbar]
  )

  return (
    <>
      <DataGrid
        isCellEditable={() => false}
        autoHeight
        disableColumnMenu
        pageSizeOptions={[5, 10, 50, 100]}
        paginationMode={paginationMode ?? 'server'}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
        }}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        {...props}
        sx={{
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </>
  )
}

export default DataTable
