import axios, { axiosFileClient } from 'services/Request'

export function getApplications(params) {
  return axios.get('/api/applications', { params })
}

export function getApplication(applicationId) {
  return axios.get(`/api/applications/${applicationId}`)
}

export function createApplication(data) {
  return axiosFileClient.post('/api/applications/', data)
}

export function editApplication(applicationId, data) {
  return axios.put(`/api/applications/${applicationId}`, data)
}

export function deleteApplication(applicationId) {
  return axios.delete(`/api/applications/${applicationId}`)
}

export function associateServices(applicationId, data) {
  return axios.post(`/api/applications/${applicationId}/services/association`, data)
}
