import { useAuth } from 'contexts/AuthProvider'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RequireAuth = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { replace: true })
    }
  }, [isAuthenticated, navigate])

  return isAuthenticated ? children : 'redirecting...'
}

export default RequireAuth
