import { useMemo } from 'react'

import { Modal as MUIModal, Box, Typography } from '@mui/material'

const Modal = ({ title, width, isOpen, handleClose, children }) => {
  const style = useMemo(() => {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: width,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    }
  }, [width])

  return (
    <MUIModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>

        {children}
      </Box>
    </MUIModal>
  )
}

export default Modal
