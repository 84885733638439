import axios, { axiosFileClient } from 'services/Request'

export function getProfiles(deviceId, params) {
  return axios.get(`/api/devices/${deviceId}/openvpn_profiles`, { params })
}

export function createProfile(deviceId, data) {
  return axiosFileClient.post(`/api/devices/${deviceId}/openvpn_profiles`, data)
}

export function editProfile(profileId, data) {
  return axiosFileClient.post(`/api/openvpn_profiles/${profileId}?_method=put`, data)
}

export function deleteProfile(profileId) {
  return axios.delete(`/api/openvpn_profiles/${profileId}`)
}
