//MUI
import { Stack, IconButton } from '@mui/material'

//Icons
import LinkOffIcon from '@mui/icons-material/LinkOff'

export const generateServiceColumns = ({ handleUnlink }) => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: true,
      renderCell: (params) => {
        return (
          <>
            <Stack direction={'row'} spacing={1}>
              <IconButton color="primary" onClick={() => handleUnlink(params.row)}>
                <LinkOffIcon fontSize="small" />
              </IconButton>
            </Stack>
          </>
        )
      },
    },
  ]
}
