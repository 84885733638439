import { useState, useEffect } from 'react'

//Contexts
import { useApp } from 'contexts/AppProvider'

//UI
import DataTable from 'components/UI/DataTable'

//Components
import { generateServiceColumns } from './columns'
import UnlinkServiceModal from './UnlinkServiceModal'

const LinkedServices = ({ application }) => {
  const { setSnackbar } = useApp()

  const [services, setServices] = useState([])
  const [unlinkingService, setUnlinkingService] = useState(null)

  useEffect(() => {
    if (!application) return

    setServices(application?.services ?? [])
  }, [application])

  const handleUnlink = (application) => {
    setUnlinkingService(application)
  }

  const handleUnlinkedApplication = (response) => {
    setServices((old) => services.filter((application) => application.id !== unlinkingService?.id))

    setUnlinkingService(false)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  return (
    <>
      <DataTable
        columns={generateServiceColumns({ handleUnlink })}
        rows={services}
        rowCount={services.length}
        paginationMode={'client'}
        disableColumnFilter={true}
      />

      <UnlinkServiceModal
        isOpen={!!unlinkingService}
        handleClose={() => setUnlinkingService(null)}
        applicationId={application?.id}
        service={unlinkingService}
        callback={handleUnlinkedApplication}
      />
    </>
  )
}

export default LinkedServices
