import { useEffect } from 'react'
import { useAuth } from 'contexts/AuthProvider'

const RequireGuest = ({ children, redirectTo = '/' }) => {
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      window.location.href = redirectTo
    }
  }, [isAuthenticated, redirectTo])

  return !isAuthenticated ? children : <>Redirecting...</>
}

export default RequireGuest
