import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

//API
import { getApplications } from 'features/applications/api/applications'
import { linkDevices } from 'features/devices/api/devices'

//MUI
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//UI
import Modal from 'components/UI/Modal'

const LinkDevicesModal = ({ isOpen, devices, handleClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(false)
  const [applications, setApplications] = useState([])

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const result = await linkDevices({
        application_id: data.application_id,
        devices: devices,
      })

      reset()
      callback(result, devices)
    } catch (e) {
      if (e?.response?.status === 400) {
        let errors = e.response.data.errors

        if (errors['application_id']) {
          setError('application_id', { type: 'api', message: errors['application_id'] })
        } else {
          setGeneralError(true)
        }
      } else {
        setGeneralError(true)
        console.log(e)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const result = await getApplications({
          pagination: 0,
        })

        setApplications(result)
      } catch (e) {
        console.log(e)
      }
    }

    fetchApplications()
  }, [])

  return (
    <>
      <Modal
        title="Link to an application"
        width={500}
        isOpen={isOpen}
        handleClose={() => {
          handleClose()
        }}
      >
        {generalError && (
          <Alert
            severity="error"
            sx={{
              my: 1,
            }}
          >
            Something went wrong. Please contact the administrator.
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          {applications.length ? (
            <>
              <FormControl fullWidth margin="normal" required error={!!errors?.application_id}>
                <InputLabel id="application-select-helper-label">Application</InputLabel>
                <Controller
                  name="application_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      labelId="application-select-helper-label"
                      id="application-select"
                      label="Application"
                      defaultValue={''}
                      value={value ?? ''}
                      onChange={onChange}
                    >
                      {applications.map((application, index) => (
                        <MenuItem value={application?.id} key={index}>
                          {application?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText>{errors?.application_id?.message}</FormHelperText>
              </FormControl>

              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
                sx={{ my: 1 }}
                fullWidth
              >
                Link
              </LoadingButton>
            </>
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Box>
      </Modal>
    </>
  )
}

export default LinkDevicesModal
