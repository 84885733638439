import { useState, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

//Contexts
import { useApp } from 'contexts/AppProvider'

//API
import { getDevices, editDevice } from '../api/devices'

//MUI
import { Paper, Button, Stack, Alert } from '@mui/material'

//Components
import { generateDevicesColumns } from '../components/List/columns'
import ClaimDeviceDrawer from '../components/List/ClaimDeviceDrawer'
import EditDeviceDrawer from '../components/List/EditDeviceDrawer'
import DeleteDeviceModal from '../components/List/DeleteDeviceModal'
import LinkDevicesModal from '../components/List/LinkDevicesModal'
import LinkAppManagerModal from '../components/List/LinkAppManagerModal'

//UI
import Title from 'components/UI/Title'
import DataTable from 'components/UI/DataTable'

const DevicesListPage = () => {
  const { setSnackbar } = useApp()
  const navigate = useNavigate()

  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false)
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [isLinkAppManagerOpen, setIsLinkAppManagerOpen] = useState(false)
  const [editingDevice, setEditingDevice] = useState(null)
  const [deletingDevice, setDeletingDevice] = useState(null)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }))

        const result = await getDevices({
          page: pageState.page,
          limit: pageState.pageSize,
        })

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: result?.data,
          total: result?.total,
          page: result?.current_page,
        }))
      } catch (e) {
        console.log(e)
      }
    }

    fetchData()
  }, [pageState.page, pageState.pageSize])

  const handleClaimedDevice = (response) => {
    setPageState((old) => ({
      ...old,
      data: [...response?.devices, ...old.data],
    }))

    setIsClaimModalOpen(false)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const openEditModal = (deviceId) => {
    setEditingDevice(deviceId)
  }

  const openDeleteModal = (deviceId) => {
    setDeletingDevice(deviceId)
  }

  const handleEditingDevice = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((device) => {
        if (device.id === response?.device.id) {
          let editDevice = response?.device
          editDevice.applications_count = device.applications_count
          return editDevice
        }

        return device
      }),
    }))

    setEditingDevice(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleDeletedDevice = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.filter((device) => device.id !== deletingDevice.id),
    }))

    setDeletingDevice(null)
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const processRowUpdate = useCallback(
    async (newRow) => {
      const response = await editDevice(newRow.id, { name: newRow.name })
      setSnackbar({ children: response?.message, severity: 'success' })

      const devices = [...pageState.data]

      devices[devices.findIndex((device) => device.id === newRow.id)] = newRow

      setPageState((old) => ({
        ...old,
        data: devices,
      }))

      return response?.device
    },
    [pageState.data, setSnackbar]
  )

  const handleLinkedDevices = (response, devices) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((device) => {
        if (devices.includes(device.id)) {
          let editDevice = device

          if (isNaN(device.applications_count)) {
            editDevice.applications_count = 1
          } else {
            editDevice.applications_count = device.applications_count + 1
          }

          return editDevice
        }

        return device
      }),
    }))

    setIsLinkModalOpen(false)
    setSelectedDevices([])
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const handleRowClick = (params) => {
    navigate(`/panel/devices/${params.id}`)
  }

  const handleLinkedAppManager = (response) => {
    setPageState((old) => ({
      ...old,
      data: pageState.data.map((device) => {
        if (selectedDevices.includes(device.id)) {
          return {
            ...device,
            application_manager: response?.application_manager,
          }
        }

        return device
      }),
    }))

    setIsLinkAppManagerOpen(false)
    setSelectedDevices([])
    setSnackbar({
      children: response?.message,
      severity: 'success',
    })
  }

  const devicesWithoutApp = pageState.data.filter((device) => device && !device.applications_count)

  return (
    <>
      <Helmet title="Devices" />

      <Stack spacing={2} direction="column">
        {devicesWithoutApp.length > 0 && (
          <Alert variant="filled" severity="warning">
            {devicesWithoutApp.length > 1
              ? `To start using your devices, please link them to an application.`
              : `To start using ${
                  devicesWithoutApp[0].name || devicesWithoutApp[0].mac_address
                }, please link it to an application.`}
          </Alert>
        )}

        <Paper sx={{ p: 2 }}>
          <Title label="Devices">
            <Stack spacing={2} direction="row">
              <Button variant="contained" onClick={() => setIsClaimModalOpen(true)}>
                Add a device
              </Button>

              <Button
                variant="contained"
                onClick={() => setIsLinkModalOpen(true)}
                disabled={!selectedDevices.length}
              >
                Link to application
              </Button>

              <Button
                variant="contained"
                onClick={() => setIsLinkAppManagerOpen(true)}
                disabled={!selectedDevices.length}
              >
                Link to app manager
              </Button>
            </Stack>
          </Title>

          <DataTable
            loading={pageState.isLoading}
            columns={generateDevicesColumns(openDeleteModal, openEditModal)}
            rows={pageState.data}
            rowCount={pageState.total}
            onPaginationModelChange={(model) => {
              setPageState((old) => ({ ...old, page: model.page + 1, pageSize: model.pageSize }))
            }}
            disableColumnFilter={true}
            processRowUpdate={processRowUpdate}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedDevices(newRowSelectionModel)
            }}
            rowSelectionModel={selectedDevices}
            onRowClick={handleRowClick}
          />
        </Paper>

        <ClaimDeviceDrawer
          isOpen={isClaimModalOpen}
          handleClose={() => setIsClaimModalOpen(false)}
          callback={handleClaimedDevice}
        />

        <EditDeviceDrawer
          isOpen={!!editingDevice}
          handleClose={() => setEditingDevice(null)}
          callback={handleEditingDevice}
          device={editingDevice}
        />

        <DeleteDeviceModal
          isOpen={!!deletingDevice}
          handleClose={() => setDeletingDevice(null)}
          device={deletingDevice}
          callback={handleDeletedDevice}
        />

        <LinkDevicesModal
          isOpen={isLinkModalOpen}
          devices={selectedDevices}
          handleClose={() => setIsLinkModalOpen(false)}
          callback={handleLinkedDevices}
        />

        <LinkAppManagerModal
          isOpen={isLinkAppManagerOpen}
          devices={selectedDevices}
          handleClose={() => setIsLinkAppManagerOpen(false)}
          callback={handleLinkedAppManager}
        />
      </Stack>
    </>
  )
}

export default DevicesListPage
