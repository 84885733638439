import { saveAs } from 'file-saver'

//MUI
import { Chip, IconButton } from '@mui/material'

//Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'

const handleDownload = async (profile) => {
  saveAs(profile.download_link, profile.name + '.ovpn')
}

export const generateVPNColumns = ({ openDeleteModal, handleEdit }) => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      sortable: false,
      flex: true,
    },
    {
      field: 'is_installed',
      headerName: 'Installed',
      sortable: false,
      flex: true,
      renderCell: (params) => {
        if (params.row.is_installed) {
          return <Chip label="Installed" color="success" />
        } else {
          return <Chip label="Not installed" />
        }
      },
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 150,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton color="primary" onClick={() => handleDownload(params.row)}>
              <DownloadIcon fontSize="small" />
            </IconButton>

            <IconButton onClick={() => handleEdit(params.row)}>
              <EditIcon fontSize="small" />
            </IconButton>

            <IconButton color="error" onClick={() => openDeleteModal(params.row)}>
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </>
        )
      },
    },
  ]
}
