import axios, { axiosFileClient } from 'services/Request'

export function getRevisions(applicationId, params) {
  return axios.get(`/api/applications/${applicationId}/revisions`, { params })
}

export function createRevision(applicationId, data) {
  return axiosFileClient.post(`/api/applications/${applicationId}/revisions`, data)
}

export function editRevision(revisionId, data) {
  return axiosFileClient.post(`/api/revisions/${revisionId}?_method=put`, data)
}

export function deleteRevision(revisionId) {
  return axios.delete(`/api/revisions/${revisionId}`)
}
