import { Routes, Route } from 'react-router-dom'

// Layout
import PanelLayout from 'layouts/Panel'
import AuthLayout from 'layouts/Auth'

// Pages
import Index from 'views/Index'
import Dashboard from 'views/panel/Dashboard'

// Features

// Authentication
import Login from 'features/auth/views/Login'
import Register from 'features/auth/views/Register'

// Devices
import DevicesListPage from 'features/devices/views/List'
import DevicesViewPage from 'features/devices/views/View'

//Applications
import ApplicationsListPage from 'features/applications/views/List'
import ApplicationsViewPage from 'features/applications/views/View'

//Services
import ServicesListPage from 'features/services/views/List'
import ServicesViewPage from 'features/services/views/View'

//Application managers
import ApplicationManagersListPage from 'features/application-managers/views/List'

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={'/'} element={<Index />} />

      <Route element={<AuthLayout />}>
        <Route path={'/login'} element={<Login />} />
        <Route path={'/register'} element={<Register />} />
      </Route>

      <Route path="/panel">
        <Route element={<PanelLayout />}>
          <Route path="" element={<Dashboard />} />

          <Route path="devices" element={<DevicesListPage />} />
          <Route path="devices/:id" element={<DevicesViewPage />} />

          <Route path="applications" element={<ApplicationsListPage />} />
          <Route path="applications/:id" element={<ApplicationsViewPage />} />

          <Route path="services" element={<ServicesListPage />} />
          <Route path="services/:id" element={<ServicesViewPage />} />

          <Route path="application-managers" element={<ApplicationManagersListPage />} />
        </Route>
      </Route>
    </Routes>
  )
}
