export const generatePublishmentColumns = () => {
  return [
    {
      field: 'device',
      headerName: 'Device',
      sortable: false,
      flex: true,
      renderCell: (params) => {
        return <>{params.row?.device?.mac_address}</>
      },
    },
    {
      field: 'application',
      headerName: 'Application',
      sortable: false,
      flex: true,
      renderCell: (params) => {
        return <>{params.row?.revision?.application?.name}</>
      },
    },
    {
        field: 'message',
        headerName: 'Message',
        sortable: false,
        flex: true,
    },
    {
      field: 'created_at',
      headerName: 'Sent at',
      sortable: false,
      flex: true,
    },
  ]
}
