//MUI
import { IconButton, Chip, Typography, Box, Tooltip } from '@mui/material'

//Icons
import CloudIcon from '@mui/icons-material/Cloud'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import EditIcon from '@mui/icons-material/Edit'
import LinkIcon from '@mui/icons-material/Link'

export const generateDevicesColumns = (openDeleteModal, openEditModal) => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
      sortable: false,
      flex: true,
      renderCell: (params) => {
        if (params?.row?.name) {
          return (
            <div className="flex flex-col my-4">
              <Typography component="div">{params.row.name}</Typography>
              <Typography variant="caption" component="div">
                {params.row.mac_address}
              </Typography>
            </div>
          )
        }

        return params?.row?.mac_address
      },
    },
    {
      field: 'is_online',
      renderHeader: (params) => (
        <strong>
          {'Status '}
          <Tooltip title="Your device will automatically connect to OpenSTS when connected to the internet. This column shows its connection status">
            <HelpOutlineIcon fontSize="small" color="disabled" />
          </Tooltip>
        </strong>
      ),
      editable: false,
      sortable: false,
      flex: true,
      renderCell: (params) => {
        if (params.row.is_online) {
          return <Chip label="Online" color="success" />
        }

        return <Chip label="Offline" />
      },
    },
    // {
    //   field: 'mac_address',
    //   headerName: 'Mac Address',
    //   sortable: false,
    //   flex: true,
    // },
    {
      field: 'application_manager',
      headerName: 'App manager',
      sortable: false,
      flex: true,
      renderCell: (params) => {
        return params?.row?.application_manager?.name
      },
    },
    {
      field: 'applications_count',
      renderHeader: (params) => (
        <strong>
          {'Linked applications '}
          <Tooltip title="To use your device, firmware needs to be installed by linking an application">
            <HelpOutlineIcon fontSize="small" color="disabled" />
          </Tooltip>
        </strong>
      ),
      editable: false,
      sortable: false,
      flex: true,
      renderCell: (params) => {
        if (params.row.applications_count > 0) {
          return <Chip label={params.row.applications_count} icon={<LinkIcon />} color="success" />
        }

        return <Chip label="Not linked" color="warning" />
      },
    },
    {
      field: 'created_at',
      headerName: 'Creation date',
      flex: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      flex: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit device">
              <IconButton
                color="white"
                onClick={(event) => {
                  event.stopPropagation()
                  openEditModal(params.row)
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete device">
              <IconButton
                color="error"
                onClick={(event) => {
                  event.stopPropagation()
                  openDeleteModal(params.row)
                }}
              >
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )
      },
    },
  ]
}
