import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

//API
import { createProfile, editProfile } from 'features/devices/api/openvpn-profiles'

//MUI
import { Drawer, Box, Typography, Alert, TextField, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//Components
import { MuiFileInput } from 'mui-file-input'

const OpenVPNProfileCreationDrawer = ({
  mode,
  deviceId,
  profile,
  isOpen,
  handleClose,
  callback,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [generalError, setGeneralError] = useState(null)

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      version_number: null,
      file: null,
    },
  })

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('name', data.name)
      if (data?.file) {
        formData.append('file', data.file, data.file?.name)
      }

      const result =
        mode === 'create'
          ? await createProfile(deviceId, formData)
          : await editProfile(profile?.id, formData)

      reset()
      callback(result)
    } catch (e) {
      if (e?.response?.status === 400) {
        let errors = e.response.data.errors

        let errorFields = ['name', 'file']

        errorFields.forEach((key) => {
          if (errors[key]) {
            setError(key, { type: 'api', message: errors[key] })
          }
        })
      } else {
        console.log(e)
        setGeneralError(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (mode === 'create') {
      reset()
    } else {
      setValue('name', profile?.name)
    }
  }, [mode, profile, reset, setValue])

  return (
    <>
      <Drawer anchor={'left'} open={isOpen} onClose={handleClose}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ width: 450, p: 4 }}>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            {mode === 'create' ? 'New profile' : 'Edit profile'}
          </Typography>

          {generalError && (
            <Alert
              severity="error"
              sx={{
                my: 1,
              }}
            >
              Something went wrong. Please contact the administrator.
            </Alert>
          )}

          <TextField
            label="Name"
            type="text"
            margin="normal"
            fullWidth
            required
            error={!!errors?.name}
            helperText={errors?.name?.message}
            autoFocus
            {...register('name')}
          />

          <Controller
            name="file"
            control={control}
            rules={{ required: mode === 'create' }}
            render={({ field, fieldState }) => (
              <MuiFileInput
                {...field}
                label="File"
                margin="normal"
                placeholder="Upload a ovpn file"
                inputProps={{ accept: '.ovpn' }}
                helperText={
                  fieldState.invalid
                    ? fieldState.error?.message
                    : mode === 'edit'
                    ? 'You can leave this field empty to keep the current file.'
                    : ''
                }
                error={fieldState.invalid}
              />
            )}
          />

          <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isLoading}
              sx={{ my: 1 }}
              fullWidth
            >
              {mode === 'create' ? 'Create' : 'Save'}
            </LoadingButton>

            <Button variant="text" fullWidth onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}

export default OpenVPNProfileCreationDrawer
