import { useState } from 'react'

//API
import { associateApplications } from 'features/services/api/services'

//MUI
import { Box, Typography, Alert, Stack, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

//UI
import Modal from 'components/UI/Modal'

const UnlinkServiceModal = ({ isOpen, handleClose, applicationId, service, callback }) => {
  const [isUnlinking, setIsUnlinking] = useState(false)
  const [generalError, setGeneralError] = useState(false)

  const handleUnlink = async () => {
    try {
      setIsUnlinking(true)

      const result = await associateApplications(service?.id, {
        type: 'unlink',
        applications: [applicationId],
      })

      callback(result)
    } catch (e) {
      setGeneralError(true)
      console.log(e)
    } finally {
      setIsUnlinking(false)
    }
  }

  return (
    <Modal title="Unlink a service" width={400} isOpen={isOpen} handleClose={handleClose}>
      {generalError && (
        <Alert
          severity="error"
          sx={{
            my: 1,
          }}
        >
          Something went wrong. Please contact the administrator.
        </Alert>
      )}

      <Box component="div">
        <Typography variant="body1" component="p" sx={{ my: 2 }}>
          Are you sure that you want to unlink service {service?.name}?
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={handleClose} fullWidth>
            No, cancel it
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isUnlinking}
            onClick={handleUnlink}
            fullWidth
          >
            Unlink
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  )
}

export default UnlinkServiceModal
