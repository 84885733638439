//Context
import { useApp } from 'contexts/AppProvider'

//MUI
import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { Toolbar, IconButton, Typography } from '@mui/material'

//Icons
import MenuIcon from '@mui/icons-material/Menu'

//Components
import AccountMenu from './Account'

const Navbar = () => {
  const { isDrawerOpen, setIsDrawerOpen, drawerWidth } = useApp()

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }))

  return (
    <AppBar position="fixed" open={isDrawerOpen}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          sx={{
            marginRight: '36px',
            ...(isDrawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Dashboard
        </Typography>

        <AccountMenu />
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
